var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b10-base',[_c('ValidationProvider',{attrs:{"name":"idtdireccion","rules":_vm.formRules.idtdireccion},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b10-autocomplete',{attrs:{"items":_vm.tdirecciones,"item-value":"idtdireccion","item-text":"descripcion","label":"Tipo de dirección","clearable":"","error-messages":errors},model:{value:(_vm.form.idtdireccion),callback:function ($$v) {_vm.$set(_vm.form, "idtdireccion", $$v)},expression:"form.idtdireccion"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"idzona","rules":_vm.formRules.idzona},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b10-autocomplete',{attrs:{"items":_vm.zonas,"item-value":"idzona","item-text":"descripcion","label":"Zona","clearable":"","error-messages":errors},model:{value:(_vm.form.idzona),callback:function ($$v) {_vm.$set(_vm.form, "idzona", $$v)},expression:"form.idzona"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"idtvia","rules":_vm.formRules.idtvia},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b10-autocomplete',{attrs:{"items":_vm.tvias,"item-value":"idtvia","item-text":"descripcion","label":"Tipo de vía","clearable":"","error-messages":errors},model:{value:(_vm.form.idtvia),callback:function ($$v) {_vm.$set(_vm.form, "idtvia", $$v)},expression:"form.idtvia"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"calle","rules":_vm.formRules.calle},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"clearable":"","flat":"","label":"Calle","error-messages":errors},model:{value:(_vm.form.calle),callback:function ($$v) {_vm.$set(_vm.form, "calle", $$v)},expression:"form.calle"}})]}}])}),_c('b10-text-field',{attrs:{"clearable":"","flat":"","label":"Número","validation-rules":_vm.formRules.num},model:{value:(_vm.form.num),callback:function ($$v) {_vm.$set(_vm.form, "num", $$v)},expression:"form.num"}}),_c('b10-text-field',{attrs:{"clearable":"","flat":"","label":"Otros datos","validation-rules":_vm.formRules.otros_datos},model:{value:(_vm.form.otros_datos),callback:function ($$v) {_vm.$set(_vm.form, "otros_datos", $$v)},expression:"form.otros_datos"}}),_c('ValidationProvider',{attrs:{"name":"idpais","rules":_vm.formRules.idpais},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b10-autocomplete',{attrs:{"items":_vm.paises,"item-value":"idpais","item-text":"descripcion","label":"País","clearable":"","error-messages":errors},on:{"change":_vm.changePaisSelected},model:{value:(_vm.form.idpais),callback:function ($$v) {_vm.$set(_vm.form, "idpais", $$v)},expression:"form.idpais"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"idprovincia","rules":_vm.formRules.idprovincia},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b10-autocomplete',{attrs:{"items":_vm.provincias,"item-value":"idprovincia","item-text":"descripcion","label":"Provincia","clearable":"","error-messages":errors},on:{"change":_vm.changeProvinciaSelected},model:{value:(_vm.form.idprovincia),callback:function ($$v) {_vm.$set(_vm.form, "idprovincia", $$v)},expression:"form.idprovincia"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"idlocalidad","rules":_vm.formRules.idlocalidad},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b10-autocomplete',{attrs:{"disabled":!_vm.form.idprovincia,"items":_vm.localidades,"item-value":"idlocalidad","item-text":"descripcion","label":"Localidad","clearable":"","error-messages":errors},model:{value:(_vm.form.idlocalidad),callback:function ($$v) {_vm.$set(_vm.form, "idlocalidad", $$v)},expression:"form.idlocalidad"}})]}}])}),_c('b10-text-field',{attrs:{"clearable":"","flat":"","label":"Código postal","validation-rules":_vm.formRules.cp},model:{value:(_vm.form.cp),callback:function ($$v) {_vm.$set(_vm.form, "cp", $$v)},expression:"form.cp"}}),_c('b10-textarea',{attrs:{"counter":"","label":"Observaciones","name":"observacion","validation-rules":_vm.formRules.observacion},model:{value:(_vm.form.observacion),callback:function ($$v) {_vm.$set(_vm.form, "observacion", $$v)},expression:"form.observacion"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }