import { APIFilter } from '@/utils/api'

export default {
  async selectLookups (Vue, idsParametros) {
    let apiCalls = []
    const apiFilterEstadoActivo = new APIFilter()
    apiFilterEstadoActivo.addGT('estado', 0)
    apiCalls.push({ name: 'selectPais', method: 'pais.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectTdireccion', method: 'tdireccion.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectTvia', method: 'tvia.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectZona', method: 'zona.select', params: { filter: apiFilterEstadoActivo } })
    const apiFilterParamertos = new APIFilter()
    apiFilterParamertos.addIn('idparametro', idsParametros)
    apiCalls.push({ name: 'selectParametro', method: 'parametro.select', params: { filter: apiFilterParamertos } })
    const apiFilterMetadatosForm = new APIFilter()
    apiFilterMetadatosForm.addLT('estado', 0)
    apiCalls.push({ name: 'selectMetadatosForm', method: 'clienteDireccion.select', params: { filter: apiFilterMetadatosForm } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectFormData (Vue, id) {
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idcliente_direccion', id)
    const resp = await Vue.$api.call('clienteDireccion.select', { filter: apiFilter })
    return resp.data.result.dataset[0]
  },
  async selectProvincia (Vue, idpais) {
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idpais', idpais)
      .addGT('estado', 0)
    const resp = await Vue.$api.call('provincia.select', {
      filter: apiFilter
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectLocalidad (Vue, idprovincia) {
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idprovincia', idprovincia)
      .addGT('estado', 0)
    const resp = await Vue.$api.call('localidad.select', {
      filter: apiFilter
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
}
